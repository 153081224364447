import React from "react";
import Footer from "../components/Footer/Footer";
import FrostedNav from "../components/Nav/FrostedNav/FrostedNav"
import FormPopup from "../components/HubSpot/FormPopup/FormPopup";
export default function Layout(props) {
  return (
    <div>
      <FrostedNav
      toggleModal={props.toggleModal}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      formTitle={props.formTitle}
        setFormTitle={props.setFormTitle}
      />
      <main>{props.children}</main>
      <FormPopup
      toggleModal={props.toggleModal}
            isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        formTitle={props.formTitle}
      />
      <Footer
      toggleModal={props.toggleModal}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      formTitle={props.formTitle}
      setFormTitle={props.setFormTitle}
      />
    </div>
  );
}