import React from 'react'
import "./TextAndImageRightToLeft.css"

export default function TextAndImageRightToLeft(props) {
  return (
    <div className='TextAndImageRightToLeft__container'>
      <div className='TextAndImageRightToLeft__right'>
        <h1 style={{ textAlign: 'center' }}>{props.H1}</h1>
        <h3 style={{ textAlign: 'center' }}> {props.H3}</h3>
        {props.clickHere === true ? (
          <p>{ props.text} <a style={{color:'white'}} href={props.link}>Click here to learn more.</a></p>
        ): (
          <p>{ props.text} <br /> <br /> { props.text2}</p>    
        )}
        
      </div>
      <div className='TextAndImageRightToLeft__left'>
        <img className='TextAndImageRightToLeft__img' src={props.mainImage} alt={props.alt} />
      </div>
    </div>
  )
}
