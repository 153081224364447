// HubSpotForm.js
import React, { useEffect } from 'react';

const HubspotApplicationForm = ({ portalId, formId, region, targetId }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//js.hsforms.net/forms/embed/v2.js";
        script.async = true;
        script.charset = 'utf-8';
      document.body.appendChild(script);
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // This enables the smooth scrolling effect
    });

        script.onload = () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region,
                    portalId,
                    formId,
                    target: `#${targetId}`
                });
            }
        };

        // Cleanup function to remove the script element when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, [portalId, formId, region, targetId]);

    return <div id={targetId}></div>;
};

export default HubspotApplicationForm;
