const Jobs = [
  {
    title:"Business Process Creator",
    location: "Hybrid position.  Some documentation work will be on site with the client or my accounting firm, Know Your Numbers Accounting PLLC; however, the process writing can be done from home.",
    purpose: "To help my and other small businesses to improve operational efficiency and increase business valuation through the creation, implementation and maintenance of business processes.",
    responsibilities: "From documenting business processes to final product of a coherent and functional business process to updating existing business processes.",
    requirements: {
      Educational_Background: "A bachelor’s degree in business administration, Management, Information Systems, or a related field.",
      Strong_writing_skills: "Ability to clearly and concisely document processes and instructions.",
      Analytical_skills: "Capability to analyze and optimize workflows and business processes.",
      Technical_proficiency: "Familiarity with process mapping tools and business process management software.",
      Communication_skills: "Effective at communicating with stakeholders at all levels to gather necessary information and explain process changes.",
      Attention_to_detail: "Precision in documenting processes and identifying potential areas for improvement.",
      Experience: "Experience in process documentation or a similar role within specific industries can be beneficial.  Background in project management or working with cross-functional teams often helps in understanding and documenting complex processes.",
      Soft_Skills: "Problem-solving abilities to identify issues within processes and propose effective solutions.  Organizational skills to manage multiple tasks and deadlines efficiently.",
      Performance_Expectations:"Produce quality business processes in a timely manner that are easy to understand and well-organized.",
    },
    Benefits_and_Compensation: "This is a part-time position with the potential to become full-time.  The starting hourly wage will be $30 with potential increases based on performance.  After two months of service the eventual employee will be eligible to participate in the company’s 401k.  Sales commissions for bringing in new business.",
    Challenges_and_Opportunities:"Challenges include the need to create many processes from the ground up for a small accounting firm from operational to hiring to payroll to marketing.  Opportunities include the possibility of being on the ground floor of a new business that is developing business process software targeted at small business owners.  Your input in design and development would be invaluable.",
    
  },
];

export default Jobs


// Job

// {
//   title:"",
//   location: "",
//   purpose: "",
//   responsibilities: "",
//   requirements: {
//     Educational_Background: "",
//     Strong_writing_skills: "",
//     Analytical_skills: "",
//     Technical_proficiency: "",
//     Communication_skills: "",
//     Attention_to_detail: "",
//     Experience: "",
//     Soft_Skills: "",
//     Performance_Expectations:"",
//   },
//   Benefits_and_Compensation: "",
//   Challenges_and_Opportunities:"",
  
// }