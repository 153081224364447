import React from 'react'
import "./Services.css"
import HeroSection from '../../components/Sections/HeroSection/HeroSection'

import BackgroundImage from "../../images/backgrounds/home.jpg"
import WhySectionsContainer from '../../containers/WhySectionsContainer/WhySectionsContainer'
import CTAButtonSection from '../../components/Sections/CTAButtonSection/CTAButtonSection'




export default function Services(props) {
  return (
    <div>
      <HeroSection
      BackgroundImage={BackgroundImage}
      mainTitle="Custom Solutions Tailored to Your Business Needs."
      subtitle="Every organization is different, and so are your needs. That's why Know Your Processes LLC offers customizable solutions that fit precisely what you're looking for. From documenting existing processes to integrating new tools, we handle it all, so you don’t have to."
      />
      <WhySectionsContainer
        title="Our Services Include"
        cardOneTitle="Centralized Management:"
        cardOneSubtitle="Keep all your processes in one place for easy access."
        cardTwoTitle="Ease of Training:"
        cardTwoSubtitle="Bring new team members up to speed quickly with multimedia resources."
        cardThreeTitle="Consistent Updates:"
        cardThreeSubtitle="As your business evolves, so does our support."
      />
      <CTAButtonSection
      toggleModal={props.toggleModal}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        formTitle={props.formTitle}
        setFormTitle={props.setFormTitle}
        title="Are you ready to take control of your business processes?"
        buttonOne={true}
        buttonOneText="Subscribe to Our SaaS"
        buttonTwo={true}
        buttonTwoText="Let Us Document Your Processes Today"
        // bottomText="and transform the way you work today!"
      />
    </div>
  )
}
