import React from 'react'
import "./IncreasedRevenueOpportunities.css"


import TextAndImageLeftToRight from '../../components/Sections/TextAndImageLeftToRight/TextAndImageLeftToRight'
import TextAndImageRightToLeft from '../../components/Sections/TextAndImageRightToLeft/TextAndImageRightToLeft'

import ReducedOperationalCostsImg from "../../images/graphics/Assets copy.jpg"
import CloserLook from "../../images/graphics/Team.jpg"
import CostReductions from "../../images/graphics/Significant Cost Reductions.jpg"
import NewProductLines from "../../images/graphics/Accounts.jpg"

export default function IncreasedRevenueOpportunities() {
  return (
    <div className='services-page__container '>
    <div
      className='services-page__hero IncreasedRevenueOpportunities'>
      <div className="overlay"></div>
      <div className="content">
        <h1>Increased Revenue Opportunities:</h1>
          <h3> Estimated Revenue Growth: 10% to 20%</h3>
          <h3 style={{width:'80%'}}>For small business owners, the path to growth is often paved with the efficient use of resources and the strategic expansion into new markets or product lines. Imagine a business environment where streamlined operations not only cut costs but also open the door to new revenue streams and opportunities. This is the promise of increased revenue opportunities through efficient processes.</h3>
      </div>
    </div>
    <TextAndImageRightToLeft
    mainImage={ReducedOperationalCostsImg}
    alt="Freeing Up Resources for Growth:"
    H1="Freeing Up Resources for Growth:"
    H3=""
    text=" Efficient processes are the engine that drives business growth. When your operations run smoothly and efficiently, valuable resources – including time, money, and manpower – are freed up. These resources can then be redirected towards growth initiatives that propel your business forward."
    text2="Our approach focuses on optimizing your existing processes to eliminate waste and maximize efficiency. This not only improves your current operations but also provides you with the capacity to invest in new projects, enter new markets, and develop new products."
    />
    <TextAndImageLeftToRight
    mainImage={CloserLook}
    alt="Expanding into New Markets:"
    H1="Expanding into New Markets:"
    H3=""
    text="Entering new markets is a significant growth opportunity for any business. However, it requires a solid foundation of efficient processes to support the expansion. With streamlined operations, your business can more easily adapt to the demands of new markets, ensuring a smoother and more successful entry."
    text2="We help you build this foundation by ensuring that your processes are scalable and adaptable. This preparation allows you to seize new market opportunities with confidence, backed by the assurance that your operations can handle increased demand and complexity."
    />
    <TextAndImageRightToLeft
    mainImage={NewProductLines}
    alt="Developing New Product Lines:"
    H1="Developing New Product Lines:"
    H3=""
    text=" Innovation is a key driver of revenue growth. Efficient processes free up the resources needed for research and development, allowing your business to explore and develop new product lines. By continuously innovating, you not only meet the evolving needs of your customers but also stay ahead of the competition."
    text2="Our services support your innovation efforts by streamlining existing operations, freeing up the necessary resources to focus on new product development. This enables you to bring new products to market faster and more efficiently, driving additional revenue growth."
      />
      <TextAndImageLeftToRight
    mainImage={CostReductions}
    alt="Realizing Revenue Growth:"
    H1="Realizing Revenue Growth:"
    H3=""
    text="The combined impact of efficient processes and strategic growth initiatives can lead to an estimated revenue growth of 10% to 20%. By optimizing your operations and expanding your market presence, you can achieve significant and sustained revenue increases."
    text2="Imagine the potential of your business with these additional revenue streams. The ability to enter new markets and develop new products not only boosts your bottom line but also enhances your market position and long-term viability."
    />
    <p style={{fontStyle:'italic'}} className='services-page__text-section'> These ranges are indicative and can vary based on specific business circumstances. Documenting processes can have a substantial impact on a company's financial performance, but the exact benefits depend on how effectively the documentation is implemented and utilized.</p>
  </div>
  )
}
