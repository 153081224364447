import React from 'react'
import "./About.css"
import HeroSection from '../../components/Sections/HeroSection/HeroSection'
import BackgroundImage from "../../images/backgrounds/home.jpg"
import PrimarySection from "../../components/Sections/PrimarySection/PrimarySection"

import Image from "../../images/backgrounds/SALES PREP.png"
import FormPopup from '../../components/HubSpot/FormPopup/FormPopup'
// import Alexmaracinarubutton from '../../components/buttons/Alexmaracinarubutton/Alexmaracinarubutton'
import { useNavigate } from 'react-router-dom';
  
export default function About(props) {
  const navigate = useNavigate();

  const handleApplyClick = () => {
    // Navigate to a dynamic route based on the job title
    const GoToServices = "/services";
    navigate(GoToServices);
   
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // This enables the smooth scrolling effect
    });
  };

  return (
    <div>
      <HeroSection
      BackgroundImage={BackgroundImage}
      mainTitle="Driven by Efficiency, Designed for Fun."
      subtitle="At Know Your Processes LLC, we believe managing business processes shouldn't be a chore. That’s why we've designed a platform that not only tackles the complexity of business management but does it in a way that's engaging and easy to understand." 
      />
      <PrimarySection
        Image={Image}
        title="Founded on the principle that every organization"
        main="no matter the size, can benefit from streamlined operations, we provide a tool that adapts to your needs. Whether you're a small business owner or running a not-for-profit, our solution molds to your unique challenges."
        secondary="Discover how we can make your business operations more efficient and fun!"
        button={true}
        buttonLink={true}
        handleApplyClick={handleApplyClick}
      />
      <FormPopup
      toggleModal={props.toggleModal}
            isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        formTitle={props.formTitle}
      />
    </div>
  )
}
