import React, { useState } from 'react';
import './styles.css'; 
import MainContainer from './containers/MainContainer';
import Layout from './layouts/MainLayout.jsx';

function App() {

  const [isOpen, setIsOpen] = useState(false);
  const [formTitle, setFormTitle] = useState("")

  const toggleModal = () => {
    setIsOpen(!isOpen);
    // setFormTitle(title);
  } 

  return (
   
    <Layout
    toggleModal={toggleModal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        formTitle={formTitle}
        setFormTitle={setFormTitle}
    >
      <MainContainer
        toggleModal={toggleModal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        formTitle={formTitle}
        setFormTitle={setFormTitle}
      />
      </Layout>
   
  );
}

export default App;