import React, { useEffect, useRef } from 'react';

const HubSpotForm = () => {
  const formRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "20350781",
          formId: "2f3fac00-fec2-4676-b7e7-bc76e78b91d6",
          target: `#${formRef.current.id}`
        });
      }
    };

    // Cleanup function to remove script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div ref={formRef} id="hs-form"></div>;
};

export default HubSpotForm;

