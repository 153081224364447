import React from 'react';
import { Routes, Route } from "react-router-dom";
import About from '../screens/About/About';
import Home from '../screens/Home/Home';
import Services from '../screens/Services/Services';
import Careers from '../screens/Careers/Careers';
import JobApplication from '../screens/JobApplication/JobApplication';
import ReducedOperationalCosts from '../screens/ReducedOperationalCosts/ReducedOperationalCosts';
import EliminatingInefficiencies from '../screens/ImprovedTimeManagement/ImprovedTimeManagement';
import EnhancedCustomerSatisfactionAndRetention from '../screens/EnhancedCustomerSatisfactionAndRetention/EnhancedCustomerSatisfactionAndRetention';
import BetterDecisionMaking from '../screens/BetterDecisionMaking/BetterDecisionMaking';
import IncreasedRevenueOpportunities from '../screens/IncreasedRevenueOpportunities/IncreasedRevenueOpportunities';

export default function MainContainer(props) {
  return (
    <Routes> 
    <Route path="/increased-revenue-opportunities" element={<IncreasedRevenueOpportunities {...props} />} />
    <Route path="/better-decision-making" element={<BetterDecisionMaking {...props} />} />
    <Route path="/better-decision-making" element={<BetterDecisionMaking {...props} />} />
    <Route path="/enhanced-customer-satisfaction-and-retention" element={<EnhancedCustomerSatisfactionAndRetention {...props} />} />
    <Route path="/improved-time-management" element={<EliminatingInefficiencies {...props} />} />
    <Route path="/reduced-operational-costs" element={<ReducedOperationalCosts {...props} />} />
    <Route path="/about" element={<About {...props} />} />
    <Route path="/services" element={<Services {...props} />} />
    <Route path="/careers" element={<Careers {...props} />} />
    <Route path="/application/:jobTitle" element={<JobApplication {...props} />} />
    <Route path="/" element={<Home {...props} />} />
    </Routes>
  );
}
