import React from 'react'
import "./PrimarySection.css"
import Alexmaracinarubutton from '../../buttons/Alexmaracinarubutton/Alexmaracinarubutton'

export default function PrimarySection(props) {
  return (

    <div className='primary-container' >
        <div className='primary-splitter'>
          <div className='primary-half-section'>
          <h1>{props.title}</h1>
          <h2>{props.main}</h2>
          <h2>{ props.secondary }</h2>
          </div>
          <div className='primary-half-section'>
            <img alt="side-by-side" className='side-by-side-img' src={props.Image} />
        </div>
        {props.button === true && (
          <Alexmaracinarubutton
            Text="Learn More About Our Philosophy"
            toggleModal={props.toggleModal}
            isOpen={props.isOpen}
            setIsOpen={props.setIsOpen}
            type="contact"
            title="Contact"
            setFormTitle={props.setFormTitle}
            handleApplyClick={props.handleApplyClick}
      />
        )}
        
        </div>
        </div>
  )
}
