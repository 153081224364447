import React from 'react'
import "./GlowCard.css"

export default function GlowCard(props) {
  return (
    <div class="notification">
    <div class="notiglow"></div>
    <div class="notiborderglow"></div>
      <div class="notititle">{props.title}</div>
      <br />
      <div class="notibody">{props.subtitle }
    </div>
  </div>
  )
}
 // Credit: https://uiverse.io/SouravBandyopadhyay/rude-tiger-29