import React from 'react'
import "./Home.css"
// import ThemeSwitcher from '../../components/buttons/ThemeSwitcher'
import MainHeadlineSubOpacity from '../../components/container-components/MainHeadlineSubOpacity/MainHeadlineSubOpacity';
// import HeroContainer from '../../components/container-components/HeroContainer/HeroContainer'

// import Image from "../../images/backgrounds/SALES PREP.png"
import Streamline from "../../images/backgrounds/Ad Universe.png"
// import WhySectionsContainer from '../../containers/WhySectionsContainer/WhySectionsContainer';
// import PrimarySection from '../../components/Sections/PrimarySection/PrimarySection';
// import SecondarySection from '../../components/Sections/SecondarySection/SecondarySection';
import CTAButtonSection from '../../components/Sections/CTAButtonSection/CTAButtonSection';
import TextAndImageLeftToRight from '../../components/Sections/TextAndImageLeftToRight/TextAndImageLeftToRight';
import TextAndImageRightToLeft from '../../components/Sections/TextAndImageRightToLeft/TextAndImageRightToLeft';

import ReducedOperationalCosts from "../../images/graphics/Reduced Operational Costs copy.jpg"
import DetailedDocs from "../../images/graphics/The Power of Detailed Documentation.webp"
import EliminatingInefficiencies from "../../images/graphics/Identifying and Eliminating Inefficiencies.webp"
import SignificantCostReductions from "../../images/graphics/Significant Cost Reductions.jpg"
import SalesPrep from "../../images/backgrounds/SALES PREP.png"


export default function Home(props) {

  return (
    <div>
      <MainHeadlineSubOpacity
        backgroundImg={Streamline}
        mainTitle="Transform Your Business with Proven Processes"
        subtitle=" Deliver a consistent product or service, boost efficiency and productivity, accelerate training and onboarding, and unlock your revenue and profitability with our expert process documentation."
        button="LEARN MORE"
        toggleModal={props.toggleModal}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        type="LEARN MORE"
        title="LEARN MORE"
        setFormTitle={props.setFormTitle}
        goToLink="/about"
      />
      <TextAndImageLeftToRight
      clickHere={true}
        link="/reduced-operational-costs"
        mainImage={ReducedOperationalCosts}
        alt="ReducedOperationalCosts"
        H1="Reduced Operational Costs:"
        H3="Estimated Savings: 10% to 30%"
        text="Detailed documentation helps in identifying and eliminating inefficiencies, leading to significant cost reductions. "
      />
      <TextAndImageRightToLeft
      clickHere={true}
        link="/improved-time-management"
        mainImage={EliminatingInefficiencies}
        alt="Improved Time Management"
        H1="Improved Time Management:"
        H3="Estimated Time Savings: 15% to 25%"
        text="Clear processes can speed up task completion and reduce the time needed for training and onboarding, leading to productivity gains."
      />
      <TextAndImageLeftToRight
      clickHere={true}
        link="/enhanced-customer-satisfaction-and-retention"
        mainImage={SignificantCostReductions}
        alt="Enhanced Customer Satisfaction and Retention"
        H1="Enhanced Customer Satisfaction and Retention:"
        H3="Estimated Revenue Increase: 5% to 15%"
        text="Consistent and reliable service improves customer satisfaction, leading to higher retention rates and increased revenue from repeat business."
      />
      <TextAndImageRightToLeft
      clickHere={true}
        link="/better-decision-making"
        mainImage={DetailedDocs}
        alt="Better Decision Making"
        H1="Better Decision Making:"
        H3="Estimated Efficiency Improvement: 10% to 20%"
        text="Access to accurate process data supports better decision-making, leading to more efficient operations and resource utilization."
      />
      <TextAndImageLeftToRight
      clickHere={true}
        link="/increased-revenue-opportunities"
        mainImage={SalesPrep}
        alt="Increased Revenue Opportunities"
        H1="Increased Revenue Opportunities:"
        H3="Estimated Revenue Growth: 10% to 20%"
        text="Efficient processes free up resources for growth initiatives and improve the company’s ability to enter new markets or expand product lines."
      />
      <CTAButtonSection
        toggleModal={props.toggleModal}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        formTitle={props.formTitle}
        setFormTitle={props.setFormTitle}
        title="Ready to simplify your business?"
        buttonOne={true}
        buttonOneText="Subscribe Now"
        buttonTwo={true}
        buttonTwoText="Hire Us to Document Your Processes"
        bottomText="and transform the way you work today!"
      />
     
    </div>
  )
}
// <PrimarySection
//         Image={Image}
//         title="Welcome to Know Your Processes LLC"
//         main="where managing your business processes becomes not only simpler but also a lot more fun!"
//         secondary="Whether you're overseeing accounting, sales, operations, or any sector of your organization, our platform centralizes everything in one intuitive interface."
//       />
//       <SecondarySection
//         Image={Streamline}
//         title="Imagine having all your key operations streamlined in one place "
//         main="– From marketing to IT – "
//         secondary="Enhanced with the ability to integrate photos and videos."
//         bottomText="This isn't just about efficiency; it's about bringing your team together, speeding up training for new employees, welcoming board members, and coordinating with third-party contractors seamlessly."
//       />
//       <WhySectionsContainer
//         title="Why Choose Us?"
//         cardOneTitle="Comprehensive Integration"
//         cardOneSubtitle="Centralize accounting, sales, operations, and more."
//         cardTwoTitle="Enhance instructions and processes with photos and videos."
//         cardTwoSubtitle="Centralize accounting, sales, operations, and more."
//         cardThreeTitle="Perfect for Teams"
//         cardThreeSubtitle="Ideal for onboarding new employees and aligning external contractors."
//       />

///////

// <PrimarySectionWithFourImageCards
//         mainTitle="Reduced Operational Costs:"
//         mainSubtitle="Estimated Savings: 10% to 30%"
//         mainSecondarySubtitle="Detailed documentation helps in identifying and eliminating inefficiencies, leading to significant cost reductions."
//         ImageOne={ReducedOperationalCosts}
//         titleOne="Reduced Operational Costs:"
//         subtitleOne="Estimated Savings: 10% to 30%"
//         primaryTextOne="Imagine a business landscape where every dollar spent is optimized, and inefficiencies are a thing of the past. This is not just a dream, but a reality that many small business owners can achieve through meticulous documentation and strategic operational management."
//         secondaryTextOne=""
//         ImageTwo={DetailedDocs}
//         titleTwo="The Power of Detailed Documentation:"
//         subtitleTwo=""
//         primaryTextTwo="Detailed documentation is the cornerstone of operational efficiency. By meticulously mapping out every process, small business owners can identify hidden inefficiencies that often go unnoticed. These inefficiencies, whether they are redundant tasks, bottlenecks, or outdated procedures, can significantly drain resources and inflate costs."
//         secondaryTextTwo="Through our comprehensive approach, we help businesses create clear and thorough documentation of their operations. This not only provides a clear picture of current processes but also highlights areas ripe for improvement."
//         ImageThree={EliminatingInefficiencies}
//         titleThree="Identifying and Eliminating Inefficiencies:"
//         subtitleThree=""
//         primaryTextThree="With a detailed blueprint of your operations, the next step is to analyze and optimize. Our experts work closely with you to scrutinize every aspect of your business processes. By identifying redundancies, streamlining workflows, and implementing best practices, we can help you achieve substantial cost savings."
//         secondaryTextThree="Think of it as fine-tuning a complex machine – each adjustment, no matter how small, contributes to a smoother, more efficient operation. This proactive approach not only reduces waste but also enhances productivity, ultimately leading to lower operational costs."
//         ImageFour={SignificantCostReductions}
//         titleFour="Significant Cost Reductions:"
//         subtitleFour=""
//         primaryTextFour="The results speak for themselves: businesses that embrace detailed documentation and operational optimization can see savings ranging from 10% to 30%. These savings can then be reinvested into the business, fueling growth and innovation."
//         secondaryTextFour="By reducing operational costs, small business owners can enjoy greater financial flexibility, allowing them to focus on what they do best – growing their business and serving their customers."
//       />
//       <SecondarySectionWithFourImageCards
//         mainTitle="Improved Time Management:"
//         mainSubtitle="Estimated Time Savings: 15% to 25%"
//         secondarySubtitle="Clear processes can speed up task completion and reduce the time needed for training and onboarding, leading to productivity gains."
//         imageOne={ReducedOperationalCosts}
//         titleOne="Improved Time Management:"
//         subtitleOne="Estimated Time Savings: 15% to 25%"
//         primaryTextOne="In the fast-paced world of small business, time is one of your most valuable assets. Efficient time management can be the difference between thriving and merely surviving. Imagine reclaiming up to a quarter of your workday through streamlined processes and effective time management strategies."
//         secondaryTextOne=""
//         imageTwo={DetailedDocs}
//         titleTwo="The Impact of Clear Processes:"
//         subtitleTwo=""
//         primaryTextTwo="Clear and well-defined processes are essential for speeding up task completion. When everyone in your business knows exactly what needs to be done, how to do it, and when it needs to be completed, productivity naturally increases. There’s no more second-guessing or unnecessary back-and-forth; tasks are handled swiftly and accurately."
//         secondaryTextTwo="Our team specializes in creating these clear, actionable processes tailored to your business needs. By documenting and standardizing workflows, we eliminate ambiguity and ensure that every team member is on the same page. This clarity not only accelerates task completion but also enhances the overall efficiency of your operations."
//         imageThree={EliminatingInefficiencies}
//         titleThree="Reducing Training and Onboarding Time:"
//         subtitleThree=""
//         primaryTextThree="One of the significant drains on time for small businesses is the training and onboarding of new employees. Without standardized procedures, onboarding can be a time-consuming and inconsistent process. Clear processes streamline this by providing new hires with a comprehensive guide to their roles and responsibilities from day one."
//         secondaryTextThree="We help you develop training materials and onboarding processes that are straightforward and effective. This means new team members can get up to speed faster, reducing the time senior staff need to spend on training and allowing them to focus on more critical tasks."
//         imageFour={SignificantCostReductions}
//         titleFour="Achieving Productivity Gains:"
//         subtitleFour=""
//         primaryTextFour="The ultimate benefit of improved time management is a significant boost in productivity. Businesses that implement clear processes and efficient training protocols can expect time savings of 15% to 25%. These savings translate to more time available for innovation, customer service, and other activities that drive growth and success."
//         secondaryTextFour="Imagine what your business could achieve with an extra quarter of your workday freed up. Enhanced productivity not only means more gets done but also that it’s done better, leading to higher quality outputs and a more motivated workforce."
//       />
//       <PrimarySectionWithFourImageCards
//         mainTitle="Enhanced Customer Satisfaction and Retention:"
//         mainSubtitle="Estimated Revenue Increase: 5% to 15%"
//         mainSecondarySubtitle="Consistent and reliable service improves customer satisfaction, leading to higher retention rates and increased revenue from repeat business."
//         ImageOne={ReducedOperationalCosts}
//         titleOne="Enhanced Customer Satisfaction and Retention:"
//         subtitleOne="Estimated Revenue Increase: 5% to 15%"
//         primaryTextOne="In the realm of small business, your customers are your lifeblood. Delivering consistent and reliable service is not just a goal – it's a necessity. Imagine a business environment where your customers are not only satisfied but loyal, returning time and again because they trust in the quality of your service. This is the key to sustained growth and profitability."
//         secondaryTextOne=""
//         ImageTwo={DetailedDocs}
//         titleTwo="The Importance of Consistency and Reliability:"
//         subtitleTwo=""
//         primaryTextTwo="Consistency and reliability are the twin pillars of excellent customer service. When your customers know they can depend on you to meet their needs every time, their satisfaction naturally increases. This trust builds a strong foundation for long-term relationships and fosters a sense of loyalty that is hard to break."
//         secondaryTextTwo="We help you establish and maintain these pillars by developing standardized procedures and best practices tailored to your business. By ensuring that every interaction is seamless and consistent, we elevate the overall customer experience, making sure that your clients always leave with a positive impression."
//         ImageThree={EliminatingInefficiencies}
//         titleThree="Boosting Customer Retention Rates:"
//         subtitleThree=""
//         primaryTextThree="Customer retention is a direct result of high satisfaction levels. When customers are happy with the service they receive, they are more likely to return. This loyalty is invaluable, as it costs significantly less to retain an existing customer than to acquire a new one. Plus, loyal customers often become advocates for your brand, spreading positive word-of-mouth and attracting new clients organically."
//         secondaryTextThree="Our strategies focus on creating a customer-centric approach that prioritizes satisfaction at every touchpoint. From initial contact to post-purchase support, we ensure that your customers feel valued and appreciated, leading to higher retention rates and a more stable revenue stream."
//         ImageFour={SignificantCostReductions}
//         titleFour="Increasing Revenue from Repeat Business:"
//         subtitleFour=""
//         primaryTextFour="The financial impact of enhanced customer satisfaction and retention is significant. Businesses that consistently deliver excellent service can expect an estimated revenue increase of 5% to 15% from repeat business. These returning customers not only spend more over time but also tend to purchase higher-margin products and services, boosting your overall profitability."
//         secondaryTextFour="Imagine the growth potential when your customers keep coming back and bringing others with them. Enhanced customer satisfaction translates directly into increased revenue, providing a solid foundation for your business to thrive and expand."
//       />
//       <SecondarySectionWithFourImageCards
//         mainTitle="Better Decision Making:"
//         mainSubtitle="Estimated Efficiency Improvement: 10% to 20%"
//         secondarySubtitle="Access to accurate process data supports better decision-making, leading to more efficient operations and resource utilization."
//         imageOne={ReducedOperationalCosts}
//         titleOne="Better Decision Making:"
//         subtitleOne="Estimated Efficiency Improvement: 10% to 20%"
//         primaryTextOne="In the dynamic landscape of small business, the ability to make informed decisions quickly is a crucial competitive advantage. Imagine having access to precise and timely data that guides every choice you make, leading to more efficient operations and optimal resource utilization. This is the essence of better decision-making."
//         secondaryTextOne=""
//         imageTwo={DetailedDocs}
//         titleTwo="The Power of Accurate Process Data:"
//         subtitleTwo=""
//         primaryTextTwo="Accurate process data is the backbone of effective decision-making. When you have a clear, data-driven understanding of your operations, you can identify strengths to build on and weaknesses to address. This clarity empowers you to make strategic decisions that propel your business forward."
//         secondaryTextTwo="Our services ensure that you have access to comprehensive and precise process data. By implementing robust data collection and analysis systems, we provide you with the insights needed to understand your business operations deeply. This information forms the foundation for making decisions that enhance efficiency and effectiveness."
//         imageThree={EliminatingInefficiencies}
//         titleThree="Supporting Strategic Choices:"
//         subtitleThree=""
//         primaryTextThree="With accurate data at your fingertips, you can make strategic choices with confidence. Whether it's allocating resources, optimizing workflows, or investing in new initiatives, data-driven decision-making reduces uncertainty and increases the likelihood of success."
//         secondaryTextThree="We help you interpret and utilize your data to its fullest potential. By translating raw data into actionable insights, we guide you in making informed decisions that align with your business goals. This approach not only improves current operations but also sets the stage for long-term growth and sustainability."
//         imageFour={SignificantCostReductions}
//         titleFour="Improving Operational Efficiency:"
//         subtitleFour=""
//         primaryTextFour="Better decision-making directly translates to improved operational efficiency. When you understand where inefficiencies lie, you can take targeted actions to address them. This proactive management can lead to an estimated efficiency improvement of 10% to 20%."
//         secondaryTextFour="Imagine the impact on your business when every decision is backed by solid data. From reducing waste to optimizing resource allocation, the gains in efficiency are substantial. This not only enhances your bottom line but also positions your business to respond swiftly to market changes and opportunities."
//       />
//       <PrimarySectionWithFourImageCards
//         mainTitle="Increased Revenue Opportunities:"
//         mainSubtitle="Estimated Revenue Growth: 10% to 20%"
//         mainSecondarySubtitle="Efficient processes free up resources for growth initiatives and improve the company’s ability to enter new markets or expand product lines."
//         ImageOne={ReducedOperationalCosts}
//         titleOne="Increased Revenue Opportunities:"
//         subtitleOne="Estimated Revenue Growth: 10% to 20%"
//         primaryTextOne="For small business owners, the path to growth is often paved with the efficient use of resources and the strategic expansion into new markets or product lines. Imagine a business environment where streamlined operations not only cut costs but also open the door to new revenue streams and opportunities. This is the promise of increased revenue opportunities through efficient processes."
//         secondaryTextOne=""
//         ImageTwo={DetailedDocs}
//         titleTwo="Freeing Up Resources for Growth:"
//         subtitleTwo=""
//         primaryTextTwo="Efficient processes are the engine that drives business growth. When your operations run smoothly and efficiently, valuable resources – including time, money, and manpower – are freed up. These resources can then be redirected towards growth initiatives that propel your business forward."
//         secondaryTextTwo="Our approach focuses on optimizing your existing processes to eliminate waste and maximize efficiency. This not only improves your current operations but also provides you with the capacity to invest in new projects, enter new markets, and develop new products."
//         ImageThree={EliminatingInefficiencies}
//         titleThree="Expanding into New Markets:"
//         subtitleThree=""
//         primaryTextThree="Entering new markets is a significant growth opportunity for any business. However, it requires a solid foundation of efficient processes to support the expansion. With streamlined operations, your business can more easily adapt to the demands of new markets, ensuring a smoother and more successful entry."
//         secondaryTextThree="We help you build this foundation by ensuring that your processes are scalable and adaptable. This preparation allows you to seize new market opportunities with confidence, backed by the assurance that your operations can handle increased demand and complexity."
//         ImageFour={SignificantCostReductions}
//         titleFour="Developing New Product Lines:"
//         subtitleFour=""
//         primaryTextFour="Innovation is a key driver of revenue growth. Efficient processes free up the resources needed for research and development, allowing your business to explore and develop new product lines. By continuously innovating, you not only meet the evolving needs of your customers but also stay ahead of the competition."
//         secondaryTextFour="Our services support your innovation efforts by streamlining existing operations, freeing up the necessary resources to focus on new product development. This enables you to bring new products to market faster and more efficiently, driving additional revenue growth."
//         hasFifth={true}
//         ImageFive={ DetailedDocs}
//         titleFive="Realizing Revenue Growth:"
//         subtitleFive=""
//         primaryTextFive="The combined impact of efficient processes and strategic growth initiatives can lead to an estimated revenue growth of 10% to 20%. By optimizing your operations and expanding your market presence, you can achieve significant and sustained revenue increases."
//         secondaryTextFive="Imagine the potential of your business with these additional revenue streams. The ability to enter new markets and develop new products not only boosts your bottom line but also enhances your market position and long-term viability."
//       />