import React from 'react';
import { useParams } from 'react-router-dom';
import "./JobApplication.css"
import HubspotApplicationForm from '../../components/HubSpot/HubspotApplicationForm/HubspotApplicationForm';


export default function JobApplication(props) {
  let { jobTitle } = useParams();

  // Convert URL-friendly title back to normal and capitalize each word
  jobTitle = jobTitle
    .replace(/-/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  
  const formTargetId = `hubspotForm-${jobTitle.replace(/\s+/g, '-')}`; // Ensure the ID is unique

  return (
    <div className='job-application__container'>
      <h2>Application for {jobTitle}</h2>
      <HubspotApplicationForm
      portalId="20350781"
      formId="9365a31e-7fd7-4e5f-a522-88c3bf137b54"
      region="na1"
      targetId={formTargetId}
      />
    </div>
  );
}
