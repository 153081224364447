import React, { createContext, useContext, useState } from 'react';

// Create a context for the theme
const ThemeContext = createContext();

// Create the provider component
export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('default'); // 'default' or 'dark-theme'

  // Change The Theme
  const toggleTheme = () => {
    if (theme === 'default') {
      setTheme('accountant-theme');
    } else if (theme === 'accountant-theme') {
      setTheme('dark-theme');
    } else {
      setTheme('default');
    }
  };


  

  return (
    <ThemeContext.Provider value={{ toggleTheme  }}>
      <div className={theme}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
}

// Hook to use the theme context
export function useTheme() {
  return useContext(ThemeContext);
}
