import React from 'react'
import "./Alexmaracinarubutton.css"

export default function Alexmaracinarubutton(props) {



  return (
    <button onClick={() => {
      if (props.handleApplyClick) {
        props.handleApplyClick();
      } else {
        props.toggleModal();
        props.setFormTitle(props.title);
      }
    }
  }className="cta">
      <span>{props.Text}</span>
  <svg width="15px" height="10px" viewBox="0 0 13 10">
    <path d="M1,5 L11,5"></path>
    <polyline points="8 1 12 5 8 9"></polyline>
  </svg>
</button>

  )
}
// Credit: https://uiverse.io/alexmaracinaru/brown-bobcat-65