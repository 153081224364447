import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./JobSection.css";
import Alexmaracinarubutton from '../../buttons/Alexmaracinarubutton/Alexmaracinarubutton';

export default function JobSection({ job }) {
  const navigate = useNavigate();

  // Function to handle button click
  const handleApplyClick = () => {
    // Navigate to a dynamic route based on the job title
    const jobApplicationPath = `/application/${job.title.toLowerCase().replace(/\s+/g, '-')}`;
    navigate(jobApplicationPath);
  };

  return (
    <div className="job-section">
      <h3>{job.title}</h3>
      <p><strong>Location:</strong> {job.location}</p>
      <p><strong>Purpose:</strong> {job.purpose}</p>
      <p><strong>Main Responsibilities:</strong> {job.responsibilities}</p>
      <ul>
        {Object.entries(job.requirements).map(([key, value]) => (
          <li key={key}><strong>{key.replace(/_/g, ' ')}:</strong> {value}</li>
        ))}
      </ul>
      <p><strong>Benefits and Compensation:</strong> {job.Benefits_and_Compensation}</p>
      <p><strong>Challenges and Opportunities:</strong> {job.Challenges_and_Opportunities}</p>
      <Alexmaracinarubutton
      handleApplyClick={handleApplyClick}
      Text="APPLY"
      // toggleModal={props.toggleModal}
      //   isOpen={props.isOpen}
      //   setIsOpen={props.setIsOpen}
      //   type="contact"
      //   title="Contact"
      //   setFormTitle={props.setFormTitle}
  />
    </div>
  );
}
