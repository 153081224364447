import React from 'react'
import "./BetterDecisionMaking.css"


import TextAndImageLeftToRight from '../../components/Sections/TextAndImageLeftToRight/TextAndImageLeftToRight'
import TextAndImageRightToLeft from '../../components/Sections/TextAndImageRightToLeft/TextAndImageRightToLeft'

import ReducedOperationalCostsImg from "../../images/graphics/Identifying\ and\ Eliminating\ Inefficiencies.webp"
import CloserLook from "../../images/graphics/Team.jpg"
import CostReductions from "../../images/graphics/Reduced Operational Costs copy.jpg"


export default function BetterDecisionMaking() {
  return (
    <div className='services-page__container '>
    <div
      className='services-page__hero BetterDecisionMaking'>
      <div className="overlay"></div>
      <div className="content">
        <h1>Better Decision Making:</h1>
          <h3>Estimated Efficiency Improvement: 10% to 20%</h3>
          <h3 style={{width:'80%'}}>In the dynamic landscape of small business, the ability to make informed decisions quickly is a crucial competitive advantage. Imagine having access to precise and timely data that guides every choice you make, leading to more efficient operations and optimal resource utilization. This is the essence of better decision-making.</h3>
      </div>
    </div>
    <TextAndImageRightToLeft
    mainImage={ReducedOperationalCostsImg}
    alt="The Power of Accurate Process Data:"
    H1="The Power of Accurate Process Data:"
    H3=""
    text="Accurate process data is the backbone of effective decision-making. When you have a clear, data-driven understanding of your operations, you can identify strengths to build on and weaknesses to address. This clarity empowers you to make strategic decisions that propel your business forward."
    text2="Our services ensure that you have access to comprehensive and precise process data. By implementing robust data collection and analysis systems, we provide you with the insights needed to understand your business operations deeply. This information forms the foundation for making decisions that enhance efficiency and effectiveness."
    />
    <TextAndImageLeftToRight
    mainImage={CloserLook}
    alt="Supporting Strategic Choices: "
    H1="Supporting Strategic Choices: "
    H3=""
    text="One of the significant drains on time for small businesses is the training and onboarding of new employees. Without standardized procedures, onboarding can be a time-consuming and inconsistent process. Clear processes streamline this by providing new hires with a comprehensive guide to their roles and responsibilities from day one."
    text2="We help you develop training materials and onboarding processes that are straightforward and effective. This means new team members can get up to speed faster, reducing the time senior staff need to spend on training and allowing them to focus on more critical tasks."
    />
    <TextAndImageRightToLeft
    mainImage={CostReductions}
    alt="Improving Operational Efficiency:"
    H1="Improving Operational Efficiency:"
    H3=""
    text="Better decision-making directly translates to improved operational efficiency. When you understand where inefficiencies lie, you can take targeted actions to address them. This proactive management can lead to an estimated efficiency improvement of 10% to 20%."
    text2="Imagine the impact on your business when every decision is backed by solid data. From reducing waste to optimizing resource allocation, the gains in efficiency are substantial. This not only enhances your bottom line but also positions your business to respond swiftly to market changes and opportunities."
    />
    <p style={{fontStyle:'italic'}} className='services-page__text-section'> These ranges are indicative and can vary based on specific business circumstances. Documenting processes can have a substantial impact on a company's financial performance, but the exact benefits depend on how effectively the documentation is implemented and utilized.</p>
  </div>
  )
}
