import React from 'react'
import "./WhySectionsContainer.css"
// import backgroundImage from '../../images/backgrounds/SALES PREP.png';
import GlowCard from '../../components/cards/GlowCard/GlowCard';
// import Alexmaracinarubutton from '../../components/buttons/Alexmaracinarubutton/Alexmaracinarubutton';

export default function WhySectionsContainer(props) {
  return (
    <div className='why-section__container'>
      <div className='why-section__title'>
        <h1>{props.title}</h1>
      </div>
      <br /><br />
      <div className='three-item-flex'>
        <GlowCard
        title={props.cardOneTitle}
          subtitle={props.cardOneSubtitle}
        />
        <GlowCard
        title={props.cardTwoTitle}
        subtitle={props.cardTwoSubtitle}
        />
        <GlowCard
        title={props.cardThreeTitle}
        subtitle={props.cardThreeSubtitle}
        />
      </div>
      
    </div>
  )
}

     