import React from 'react'
import "./HeroSection.css"
import Alexmaracinarubutton from '../../buttons/Alexmaracinarubutton/Alexmaracinarubutton'


export default function HeroSection(props) {
  return (
    <div
      className="hero-section__container"
      style={{ backgroundImage: `url(${props.BackgroundImage})` }}
    >
    <div className="hero-section__content">
          <h1>{ props.mainTitle }</h1>
          <h2>{props.subtitle}</h2>
          <br /><br /><br />
          {props.button && (
            <Alexmaracinarubutton
              Text={props.button}
  
            />
          )}
    </div>
  </div>
  )
}
