import React, { useState } from 'react';
import "./Careers.css";
import HeroSection from '../../components/Sections/HeroSection/HeroSection';
import BackgroundImage from "../../images/backgrounds/home.jpg";
import JobsData from "../../databases/Jobs.js";
import JobSection from '../../components/Sections/JobSection/JobSection.jsx';

export default function Jobs() {
  const [selectedJob, setSelectedJob] = useState(null);

  const handleJobClick = job => {
    setSelectedJob(job === selectedJob ? null : job); // Toggle visibility or change selected job
  };

  return (
    <div>
      <HeroSection
        BackgroundImage={BackgroundImage}
        mainTitle="Career Opportunities"
        subtitle="Know Your Processes LLC"
      />
      <div className='jobs-section'>
        <div className="jobs-list">
          {JobsData.map((job, index) => (
            <div key={index} className="job-card" onClick={() => handleJobClick(job)}>
              <h3>{job.title}</h3>
              <p className="more-info">Click here for more</p>
            </div>
          ))}
        </div>
        {selectedJob && <JobSection job={selectedJob} />}
      </div>
    </div>
  );
}
