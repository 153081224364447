import React from 'react'
import "./ImprovedTimeManagement.css"

import TextAndImageLeftToRight from '../../components/Sections/TextAndImageLeftToRight/TextAndImageLeftToRight'
import TextAndImageRightToLeft from '../../components/Sections/TextAndImageRightToLeft/TextAndImageRightToLeft'

import ReducedOperationalCostsImg from "../../images/graphics/Identifying\ and\ Eliminating\ Inefficiencies.webp"
import CloserLook from "../../images/graphics/Team.jpg"
import CostReductions from "../../images/graphics/Significant Cost Reductions.jpg"

export default function EliminatingInefficiencies() {
  return (
    <div className='services-page__container '>
    <div
      className='services-page__hero EliminatingInefficiencies'>
      <div className="overlay"></div>
      <div className="content">
        <h1>Improved Time Management:</h1>
          <h3> Estimated Time Savings: 15% to 25%</h3>
          <h3 style={{width:'80%'}}>In the fast-paced world of small business, time is one of your most valuable assets. Efficient time management can be the difference between thriving and merely surviving. Imagine reclaiming up to a quarter of your workday through streamlined processes and effective time management strategies.</h3>
      </div>
    </div>
    <TextAndImageRightToLeft
    mainImage={ReducedOperationalCostsImg}
    alt="The Impact of Clear Processes:"
    H1="The Impact of Clear Processes:"
    H3=""
    text="Clear and well-defined processes are essential for speeding up task completion. When everyone in your business knows exactly what needs to be done, how to do it, and when it needs to be completed, productivity naturally increases. There’s no more second-guessing or unnecessary back-and-forth; tasks are handled swiftly and accurately."
    text2="Our team specializes in creating these clear, actionable processes tailored to your business needs. By documenting and standardizing workflows, we eliminate ambiguity and ensure that every team member is on the same page. This clarity not only accelerates task completion but also enhances the overall efficiency of your operations."
    />
    <TextAndImageLeftToRight
    mainImage={CloserLook}
    alt="Reducing Training and Onboarding Time:"
    H1="Reducing Training and Onboarding Time:"
    H3=""
    text="One of the significant drains on time for small businesses is the training and onboarding of new employees. Without standardized procedures, onboarding can be a time-consuming and inconsistent process. Clear processes streamline this by providing new hires with a comprehensive guide to their roles and responsibilities from day one."
    text2="We help you develop training materials and onboarding processes that are straightforward and effective. This means new team members can get up to speed faster, reducing the time senior staff need to spend on training and allowing them to focus on more critical tasks."
    />
    <TextAndImageRightToLeft
    mainImage={CostReductions}
    alt="Achieving Productivity Gains:"
    H1="Achieving Productivity Gains:"
    H3=""
    text="The ultimate benefit of improved time management is a significant boost in productivity. Businesses that implement clear processes and efficient training protocols can expect time savings of 15% to 25%. These savings translate to more time available for innovation, customer service, and other activities that drive growth and success."
    text2="Imagine what your business could achieve with an extra quarter of your workday freed up. Enhanced productivity not only means more gets done but also that it’s done better, leading to higher quality outputs and a more motivated workforce."
    />
    <p style={{fontStyle:'italic'}} className='services-page__text-section'> These ranges are indicative and can vary based on specific business circumstances. Documenting processes can have a substantial impact on a company's financial performance, but the exact benefits depend on how effectively the documentation is implemented and utilized.</p>
  </div>
  )
}
