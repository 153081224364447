import React from 'react'
import "./ReducedOperationalCosts.css"
import TextAndImageLeftToRight from '../../components/Sections/TextAndImageLeftToRight/TextAndImageLeftToRight'
import TextAndImageRightToLeft from '../../components/Sections/TextAndImageRightToLeft/TextAndImageRightToLeft'

import ReducedOperationalCostsImg from "../../images/graphics/Reduced Operational Costs copy.jpg"
import CloserLook from "../../images/graphics/Closer Look.jpg"
import CostReductions from "../../images/graphics/Significant Cost Reductions.jpg"

export default function ReducedOperationalCosts() {
  return (
    <div className='services-page__container'>
      <div
        className='services-page__hero reduced-operation'>
        <div className="overlay"></div>
        <div className="content">
          <h1>Reduced Operational Costs</h1>
          <h3>Estimated Savings: 10% to 30%</h3>
          <h3 style={{width:'80%'}}>Imagine a business landscape where every dollar spent is optimized, and inefficiencies are a thing of the past. This is not just a dream, but a reality that many small business owners can achieve through meticulous documentation and strategic operational management.</h3>
        </div>
      </div>
      <TextAndImageRightToLeft
      mainImage={ReducedOperationalCostsImg}
      alt="The Power of Detailed Documentation:"
      H1="The Power of Detailed Documentation:"
      H3=""
      text=" Detailed documentation is the cornerstone of operational efficiency. By meticulously mapping out every process, small business owners can identify hidden inefficiencies that often go unnoticed. These inefficiencies, whether they are redundant tasks, bottlenecks, or outdated procedures, can significantly drain resources and inflate costs."
      text2="Through our comprehensive approach, we help businesses create clear and thorough documentation of their operations. This not only provides a clear picture of current processes but also highlights areas ripe for improvement."
      />
      <TextAndImageLeftToRight
      mainImage={CloserLook}
      alt="Identifying and Eliminating Inefficiencies: "
      H1="Identifying and Eliminating Inefficiencies: "
      H3=""
      text="With a detailed blueprint of your operations, the next step is to analyze and optimize. Our experts work closely with you to scrutinize every aspect of your business processes. By identifying redundancies, streamlining workflows, and implementing best practices, we can help you achieve substantial cost savings."
      text2="Think of it as fine-tuning a complex machine – each adjustment, no matter how small, contributes to a smoother, more efficient operation. This proactive approach not only reduces waste but also enhances productivity, ultimately leading to lower operational costs."
      />
      <TextAndImageRightToLeft
      mainImage={CostReductions}
      alt="Significant Cost Reductions:"
      H1="Significant Cost Reductions:"
      H3=""
      text="The results speak for themselves: businesses that embrace detailed documentation and operational optimization can see savings ranging from 10% to 30%. These savings can then be reinvested into the business, fueling growth and innovation."
      text2="By reducing operational costs, small business owners can enjoy greater financial flexibility, allowing them to focus on what they do best – growing their business and serving their customers."
      />
      <p style={{fontStyle:'italic'}} className='services-page__text-section'> These ranges are indicative and can vary based on specific business circumstances. Documenting processes can have a substantial impact on a company's financial performance, but the exact benefits depend on how effectively the documentation is implemented and utilized.</p>
    </div>
  )
}
