import React from 'react';
import "./FormPopup.css"

import HubspotContactForm from "../HubSpotContactForm/HubSpotContactForm"
import HubspotNewsletterSignup from "../HubspotNewsletterSignup/HubspotNewsletterSignup"

export default function FormPopup(props) {


  return (
    <div className='form-popup-container'>
    
    {props.isOpen && (
      <div className="modal-overlay">
        <div className="modal-content">
            <button className="close-btn" onClick={props.toggleModal}>Close</button>
            <h2>{props.formTitle}</h2>
            {props.formTitle === "Contact" &&(
              <HubspotContactForm />
            )}
            {props.formTitle === "Subscribe" &&(
              <HubspotNewsletterSignup />
            )}
        </div>
      </div>
    )}
    </div>
  )
}
