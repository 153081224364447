import React from 'react'
import "./CTAButtonSection.css"
import Alexmaracinarubutton from '../../buttons/Alexmaracinarubutton/Alexmaracinarubutton'

import Flowtask from "../../../images/logos/Cropped_Flortask_Logo.png"

export default function CTAButtonSection(props) {
  return (
    <div className='cta-section__dark'>
      <h1>{props.title}</h1><br /><br />
      <div className='cta-section-button-holder'>
        {props.buttonOne && (
          <Alexmaracinarubutton
          Text={props.buttonOneText}
          toggleModal={props.toggleModal}
          isOpen={props.isOpen}
          setIsOpen={props.setIsOpen}
          type="subscribe"
          title="Subscribe"
          setFormTitle={props.setFormTitle}
        />
        )}
        <img className='flowtask-logo' src={Flowtask} alt="flowtask logo" />
        {props.buttonTwo && (
          
          <Alexmaracinarubutton
          Text={props.buttonTwoText}
          toggleModal={props.toggleModal}
          isOpen={props.isOpen}
          setIsOpen={props.setIsOpen}
          type="contact"
          title="Contact"
          setFormTitle={props.setFormTitle}
        />
         )}
          
          
        </div>
      <br /><br />
      
      <h3>{props.bottomText}</h3>
      </div>
  )
}
