import React from 'react'
import "./Footer.css"

import Logo from "../../images/logos/know-your-processes-logo-cutout.png"
import SocialButtonTall from '../buttons/Socials/SocialButtonTall/SocialButtonTall'

export default function Footer(props) {
  return (
    <div className='footer__container'>
      <div className='three-section-split'>
        <div className='split' >
          <img className='footer__logo' alt="Logo" src={Logo} />
          <br /><br />
          <SocialButtonTall />
        </div>
        <div className='split' >
        <a href="/"> <h4>Home</h4></a>
           <a href="/about"> <h4>About</h4></a>
          <a href="/services"> <h4>Services</h4></a>
          <a href="/careers"> <h4>Careers</h4></a>
          <h4 onClick={() => {
            props.toggleModal();
            props.setFormTitle("Contact");
          }
        }>Contact</h4>
        </div>
      </div>
      <h6>© 2024 Know Your Processes</h6>
    </div>
  )
}
